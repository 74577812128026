var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('loader',{attrs:{"retry":_vm.initialize}},[(_vm.rows != null && _vm.columns != null)?_c('div',[_c('model-table',{attrs:{"viewPermission":function () { return true; },"title":"Results","provider":_vm.tableProvider,"columns":_vm.columns,"rows-per-page-options":[0],"row-key":"name","separator":"cell","hide-pagination":"","binary-state-sort":"","sortBy":"finalScore","descending":true,"showActions":false},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"text-h6 q-pr-md"},[_vm._v("Open Call Results")]),_c('q-btn',{attrs:{"label":"Print","loading":_vm.$proxy.printing,"flat":"","icon":"print"},on:{"click":function($event){_vm.$runAsync('printing', function () { return _vm.print(); })}}}),_c('q-btn',{attrs:{"label":"Download","loading":_vm.$proxy.downloading,"flat":"","icon":"download"},on:{"click":function($event){_vm.$runAsync('downloading', function () { return _vm.download(); })}}})]},proxy:true}],null,false,3914673441)}),_c('div',{staticClass:"col-12"},[_c('form-container',_vm._g(_vm._b({attrs:{"flat":"","title":"Final File","id":_vm.openCallId,"item":_vm.openCall,"provider":_vm.$service.providers.openCalls,"crud":_vm.$service.crud.openCalls},on:{"update:item":function($event){_vm.openCall=$event}}},'form-container',Object.assign({}, _vm.$attrs, _vm.$props),false),_vm.$listeners),[_c('q-card',{attrs:{"flat":"","bordered":""}},[_c('q-card-section',[_vm._v(" Final Documents ")]),_c('q-separator'),_c('q-card-section',[_c('sub-array-form',{attrs:{"array":_vm.finalFiles,"generator":function (id) { return ({
                  file: {
                    id: id,
                    fileType: 'General',
                    name: '',
                    base64Contents: null,
                  },
                }); },"validateObject":_vm.validate},on:{"update:array":function($event){_vm.finalFiles=$event}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col-12"},[_c('file-input',{attrs:{"filled":"","stack-label":"","label":"File"},scopedSlots:_vm._u([(item.file)?{key:"append",fn:function(){return [_c('q-btn',{attrs:{"round":"","dense":"","flat":"","icon":"delete"},on:{"click":function($event){item.file = null}}})]},proxy:true}:null],null,true),model:{value:(item.file),callback:function ($$v) {_vm.$set(item, "file", $$v)},expression:"item.file"}})],1)])]}}],null,false,60903509)})],1)],1)],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }