































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "@/components/FormContainer.vue";
import FileInput from "@/components/FileInput.vue";
import DatePicker from "@/components/DatePicker.vue";
import ModelTable from "@/components/ModelTable.vue";
import { Applicant, Criterion, Score } from "@/data/models/OpenCall";
import Loader from "@/components/Loader.vue";
import { saveAs } from "file-saver";
import InMemoryDataProvider from "@/lib/InMemory/InMemoryDataProvider";
import SubArrayForm from "@/components/SubArrayForm.vue";
import openPdf from "@/lib/openPdf";
@Component({
  components: {
    FileInput,
    FormContainer,
    DatePicker,
    ModelTable,
    Loader,
    SubArrayForm,
  },
})
export default class Results extends Vue {
  @Prop()
  public openCallId!: string;

  @Prop()
  public crud!: ICrudClient<Score>;

  @Prop()
  public provider!: DataProvider<Score>;

  private applicantProvider: DataProvider<Applicant> | null = null;
  private applicants: any = [];

  private criteriaProvider: DataProvider<Criterion> | null = null;
  private criteria: any = [];

  private columns: any = null;
  private rows: any = null;
  private tableProvider: DataProvider<any> | null = null;
  private submitting = false;
  private openCall: any = null;
  private finalFiles: [] = [];
  private utc = new Date().toJSON().slice(0, 10);

  @Watch("finalFiles", { immediate: false })
  onFilesChanged() {
    if (!this.openCall) {
      return;
    }
    this.openCall.finalFiles = this.finalFiles.map((f: any) => f.file);
  }
  validate(i: any) {
    return i && !!i.file.name;
  }

  @Watch("openCall", { deep: false, immediate: false })
  async onItemChanged(newVal: any, oldVal: any) {
    this.finalFiles = this.openCall
      ? this.openCall.finalFiles.map((f: any) => ({ file: f }))
      : [];
    if (!this.openCall) {
      return;
    }
  }

  compareNames(critA: any, critB: any) {
    if (critA.name < critB.name) {
      return -1;
    }
    return 1;
  }
  async initialize() {
    this.openCall = await this.$service.providers.openCalls.fetchItemAsync(
      this.openCallId
    );
    this.applicantProvider = this.$service.providers.openCallApplicant(
      this.openCallId
    );
    this.criteriaProvider = this.$service.providers.openCallCriteria(
      this.openCallId
    );
    this.applicants = (await this.applicantProvider.fetchItemsAsync()).items;

    this.criteria = (
      await this.criteriaProvider.fetchItemsAsync({
        columnFilters: [
          { column: "parentCriterionId", value: `null`, op: "Equals" },
        ],
        orderby: 'priority'
      })
    ).items;
    const critIds = this.criteria.map((c: any) => c.id);
    const columns = [
      {
        name: "applicant",
        label: "Applicant",
        align: "left",
        sortable: true,
        headerClasses: "bg-primary text-white",
        field: (r: any) => r.applicant,
      },
    ];

    columns.push(
      ...this.criteria.map((c: any) => {
        return {
          name: "criterion_" + c.id,
          label: c.name,
          align: "left",
          sortable: true,
          headerClasses: "bg-secondary text-white",
          field: (r: any) =>
            r.scores && Object.keys(r.scores).indexOf(c.id) !== -1
              ? r.scores[c.id]
              : "",
        };
      })
    );
    columns.push({
      name: "total",
      label: "Total",
      align: "left",
      sortable: true,
      headerClasses: "bg-secondary text-white",
      field: (r: any) =>
        r.scores && Object.keys(r.scores).indexOf("total")
          ? r.scores["total"]
          : "",
    });
    if (
      this.openCall.calculationMethod &&
      (this.openCall.calculationMethod === "QualityPrice" ||
        this.openCall.calculationMethod === "EuropeanQualityPrice")
    ) {
      columns.push(
        {
          name: "budget",
          label: "Budget",
          align: "left",
          sortable: true,
          headerClasses: "bg-secondary text-white",
          field: (r: any) => (r.budget || r.budget === 0 ? r.budget : ""),
        },
        {
          name: "finalScore",
          label: "Final Score",
          align: "left",
          sortable: true,
          headerClasses: "bg-secondary text-white",
          field: (r: any) =>
            r.finalScore || r.finalScore === 0 ? r.finalScore : "",
        }
      );
    }

    this.columns = columns;
    const rows: any = [];
    for (
      let applicantIdx = 0;
      applicantIdx < this.applicants.length;
      applicantIdx++
    ) {
      const applicant = this.applicants[applicantIdx];
      const applicantId = applicant.id;
      const scores = (
        await this.provider.fetchItemsAsync({
          columnFilters: [
            {
              column: "ApplicantId",
              value: `${applicantId}`,
              op: "Equals",
            },
          ],
        })
      ).items.filter((c: any) => critIds.indexOf(c.criterionId) !== -1);
      const applicantRow = {
        applicant: applicant.name,
        scores: this.calculateScores(scores),
        budget: null,
        finalScore: -1,
      };
      if (
        this.openCall.calculationMethod &&
        (this.openCall.calculationMethod === "QualityPrice" ||
          this.openCall.calculationMethod === "EuropeanQualityPrice")
      ) {
        //
        applicantRow.budget = applicant.totalRequested;
      }
      rows.push(applicantRow);
    }
    this.rows = rows;
    for (const r of rows) {
      r.finalScore = this.getFinalScore(r);
    }
    this.tableProvider = new InMemoryDataProvider(this.rows);
  }
  getFinalScore(r: any) {
    if (!this.rows) {
      return 0;
    }
    if (this.openCall.calculationMethod === "QualityPrice") {
      const pcp = this.openCall.priceContributionPercentage / 100;

      const maxTotal = Math.max(...this.rows.map((rr: any) => rr.scores.total));
      const qs = r.scores.total / maxTotal;
      const minBudget = Math.min(...this.rows.map((rr: any) => rr.budget));
      const ps = minBudget / r.budget;
      const res = pcp * ps + (1 - pcp) * qs;
      return parseFloat(res.toFixed(2));
    } else if (this.openCall.calculationMethod === "EuropeanQualityPrice") {
      const pcp = this.openCall.priceContributionPercentage / 100;

      const maxTotal = Math.max(...this.rows.map((rr: any) => rr.scores.total));
      const minBudget = Math.min(...this.rows.map((rr: any) => rr.budget));
      const ps = minBudget / r.budget;
      const res = pcp * ps + (1 - pcp) * r.scores.total;
      return parseFloat(res.toFixed(2));
    }
    return 0;
  }
  calculateScores(scores: any[]) {
    const activeScorers = this.getActiveScorers(scores);
    const critScores = this.getScoresPerCriterion(activeScorers, scores);
    return critScores;
  }
  getScoresPerCriterion(activeScorers: any, scores: any) {
    const critScores = scores.reduce((acc: any, score: any) => {
      if (activeScorers.indexOf(score.scorerNumber) !== -1) {
        if (Object.keys(acc).indexOf(score.criterionId) !== -1) {
          acc[score.criterionId] = (acc[score.criterionId] + score.value) / 2;
        } else {
          acc[score.criterionId] = score.value;
        }
      }
      return acc;
    }, {});
    const total = Object.values(critScores).reduce(
      (acc: any, cur: any) => acc + cur,
      0
    );
    critScores["total"] = total;
    return critScores;
  }
  getActiveScorers(scores: any) {
    const scorerOneTotal = this.getScorerTotal(0, scores);
    const scorerTwoTotal = this.getScorerTotal(1, scores);
    const scorerThreeTotal = this.getScorerTotal(2, scores);
    if (
      Math.min(scorerOneTotal, scorerTwoTotal) <
      Math.max(scorerOneTotal, scorerTwoTotal) * 0.7
    ) {
      const oneTwoDiff = Math.abs(scorerOneTotal - scorerTwoTotal);
      const twoThreeDiff = Math.abs(scorerTwoTotal - scorerThreeTotal);
      const oneThreeDiff = Math.abs(scorerOneTotal - scorerThreeTotal);
      if (oneTwoDiff < twoThreeDiff) {
        if (oneTwoDiff < oneThreeDiff) {
          return [0, 1];
        } else {
          return [0, 2];
        }
      } else {
        if (twoThreeDiff < oneThreeDiff) {
          return [1, 2];
        } else {
          return [0, 2];
        }
      }
    } else {
      return [0, 1];
    }
  }

  getClosestNumbers(arr: number[]) {
    arr = arr.sort((a: number, b: number) => (a > b ? 1 : a < b ? -1 : 0));
    return arr;
  }
  getScorerTotal(scorerNumber: any, scores: any) {
    return scores
      .filter((s: any) => s.scorerNumber == scorerNumber)
      .reduce((acc: any, ss: any) => acc + ss.value, 0);
  }

  async download() {
    saveAs(
      await this.$service.downloadOpenCallResults(this.openCallId),
      `${this.openCall.title}_Final Scores_${this.utc}.xlsx`
    );
  }

  async print() {
    //openPdf(this.$service.printFinalScores(this.openCallId));

    saveAs(
      await this.$service.printFinalScores(this.openCallId),
      `${this.openCall.title}_Final Scores_${this.utc}.pdf`
    );
  }
}
